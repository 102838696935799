<template>
  <v-row class="user-bio-panel">
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title class="justify-center flex-column">
          <span class="mb-2">{{ emailTemplateData.title }}</span>
          <v-chip
            label
            small
            :color="resolveEmailActionIcon(emailTemplateData.action).color"
            :class="`v-chip-light-bg ${
              resolveEmailActionIcon(emailTemplateData.action).color
            }--text font-weight-semibold text-capitalize ma-1 mb-2`"
            >{{ $t(resolveEmailActionIcon(emailTemplateData.action).text) }}</v-chip
          >
        </v-card-title>

        <v-card-text>
          <h2 v-t="'details'" class="text-xl font-weight-semibold mb-2" />

          <v-divider></v-divider>

          <v-list>
            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('language') }}:</span>
              <span class="text--secondary">{{ resolveEmailLanguageText(emailTemplateData.lang) }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('createdAt') }}:</span>
              <span class="text--secondary">{{ formatDateToMonthShort(emailTemplateData.createdAt) }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('updatedAt') }}:</span>
              <span class="text--secondary">{{ formatDateToMonthShort(emailTemplateData.updatedAt) }}</span>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions>
          <v-row justify="center">
            <v-col cols="auto">
              <v-dialog v-model="isDeleteDialogOpen" persistent max-width="650px">
                <template #activator="{ on, attrs }">
                  <v-btn small color="error" v-bind="attrs" v-on="on" @click="isDeleteDialogOpen = true">
                    <v-icon class="me-2">{{ icons.mdiTrashCanOutline }}</v-icon>
                    <span v-t="'delete'" />
                  </v-btn>
                </template>
                <v-card class="pa-sm-10 pa-3">
                  <v-card-title v-t="'delete'" class="justify-center text-h5" />
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" class="d-flex align-center justify-center">
                        {{ $t('confirmationModalUndoneQuestion') }}
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="d-flex justify-center mt-3">
                        <v-btn v-t="'discard'" outlined class="me-3" @click="isDeleteDialogOpen = false" />
                        <v-btn v-t="'delete'" color="error" @click="deleteEmailTemplateHandler" />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col cols="auto">
              <v-btn small color="primary" outlined @click="duplicateHandler">
                <v-icon class="me-2" size="16">{{ icons.mdiContentDuplicate }}</v-icon>
                <span v-t="'duplicate'" />
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn small color="primary" @click="isBioDialogOpen = !isBioDialogOpen">
                <v-icon class="me-2">{{ icons.mdiPencilOutline }}</v-icon>
                <span v-t="'edit'" />
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>

      <email-bio-edit
        :is-bio-dialog-open.sync="isBioDialogOpen"
        :email-template-data.sync="emailTemplateData"
      ></email-bio-edit>
    </v-col>
  </v-row>
</template>

<script>
import { mdiTrashCanOutline, mdiPencilOutline, mdiContentDuplicate } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { useActions, useState } from 'vuex-composition-helpers'

import { useRouter } from '@core/utils'
import { formatDateToMonthShort } from '@core/utils/filter'
import EmailBioEdit from './EmailBioEdit.vue'
import useEmailList from '../../email-list/useEmailList'

export default {
  components: {
    EmailBioEdit,
  },
  setup() {
    const isBioDialogOpen = ref(false)
    const isDeleteDialogOpen = ref(false)
    const { router } = useRouter()

    const { resolveEmailActionIcon, resolveEmailLanguageText } = useEmailList()
    const { deleteEmailTemplate, duplicateEmailTemplate } = useActions('jobs', [
      'deleteEmailTemplate',
      'duplicateEmailTemplate',
    ])
    const { emailTemplateData } = useState('jobs', ['emailTemplateData'])

    const deleteEmailTemplateHandler = async () => {
      await deleteEmailTemplate(emailTemplateData.value.id)
      router.push({ name: 'apps-email-templates-list' })
    }

    const duplicateHandler = async () => {
      await duplicateEmailTemplate(emailTemplateData.value.id)
      router.push({ name: 'apps-email-templates-list' })
    }

    return {
      deleteEmailTemplateHandler,
      resolveEmailActionIcon,
      resolveEmailLanguageText,
      formatDateToMonthShort,
      duplicateHandler,

      emailTemplateData,

      isBioDialogOpen,
      isDeleteDialogOpen,
      icons: {
        mdiPencilOutline,
        mdiTrashCanOutline,
        mdiContentDuplicate,
      },
    }
  },
}
</script>
