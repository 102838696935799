<template>
  <v-dialog v-model="isBioDialogOpen" max-width="650px" @click:outside="$emit('update:is-bio-dialog-open', false)">
    <v-card class="user-edit-info pa-sm-10 pa-3">
      <v-card-title v-t="'edit'" class="justify-center text-h5" />

      <v-card-text class="mt-5">
        <v-form ref="bioEditForm" v-model="valid" class="multi-col-validation" @submit.prevent="handleFormSubmit">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="emailTemplateDataLocal.title"
                outlined
                dense
                maxlength="100"
                hide-details="auto"
                :label="$t('title')"
                :placeholder="$t('title')"
                :rules="[validators.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="emailTemplateDataLocal.action"
                :label="$t('action')"
                :placeholder="$t('action')"
                :items="defaultChoices.actionChoices"
                outlined
                dense
                hide-details
                :rules="[validators.required]"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="emailTemplateDataLocal.lang"
                :label="$t('language')"
                :placeholder="$t('language')"
                :items="defaultChoices.languageChoices"
                outlined
                dense
                hide-details
                :rules="[validators.required]"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="d-flex justify-center mt-3">
              <v-btn
                v-t="'discard'"
                outlined
                color="secondary"
                class="me-3"
                @click.prevent="$emit('update:is-bio-dialog-open', false)"
              />
              <v-btn color="primary" type="submit" :loading="loading" :disabled="!valid || !areDataChanged">
                {{ $t('save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch, computed } from '@vue/composition-api'
import { useActions } from 'vuex-composition-helpers'
import { required } from '@core/utils/validation'
import _ from 'lodash'
import emailTemplateUtils from '@/utils/templates/email/emailTemplateUtils'

export default {
  props: {
    isBioDialogOpen: {
      type: Boolean,
      required: true,
    },
    emailTemplateData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { defaultChoices } = emailTemplateUtils()
    const valid = ref(true)
    const loading = ref(false)

    const bioEditForm = ref(null)

    const emailTemplateDataLocal = ref({})
    emailTemplateDataLocal.value = _.cloneDeep(props.emailTemplateData)

    const { updateEmailTemplate } = useActions('jobs', ['updateEmailTemplate'])

    const handleFormSubmit = async () => {
      const isFormValid = bioEditForm.value.validate()
      if (!isFormValid) return

      loading.value = true
      const { success } = await updateEmailTemplate({
        id: emailTemplateDataLocal.value.id,
        payload: {
          title: emailTemplateDataLocal.value.title,
          action: emailTemplateDataLocal.value.action,
          lang: emailTemplateDataLocal.value.lang,
        },
      })
      loading.value = false

      if (success) {
        emit('update:is-bio-dialog-open', false)
      }
    }

    watch(
      () => props.isBioDialogOpen,
      () => {
        emailTemplateDataLocal.value = _.cloneDeep(props.emailTemplateData)
      },
    )

    const areDataChanged = computed(() => {
      return (
        emailTemplateDataLocal.value.title !== props.emailTemplateData.title ||
        emailTemplateDataLocal.value.action !== props.emailTemplateData.action ||
        emailTemplateDataLocal.value.lang !== props.emailTemplateData.lang
      )
    })

    return {
      handleFormSubmit,

      defaultChoices,
      valid,
      loading,
      bioEditForm,
      emailTemplateDataLocal,
      areDataChanged,

      validators: {
        required,
      },
    }
  },
}
</script>
