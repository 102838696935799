<template>
  <div id="user-view">
    <v-row>
      <v-col cols="12" md="5" lg="4">
        <bread-crumbs
          :context="{
            emailTemplateName: emailTemplateData.title,
          }"
        />
        <email-bio-panel />
      </v-col>

      <v-col cols="12" md="7" lg="8">
        <email-tab-editor v-if="emailTemplateData.id" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from '@vue/composition-api'
import { mdiAccountCancelOutline } from '@mdi/js'
import { useActions, useMutations, useState } from 'vuex-composition-helpers'
import router from '@/router'
// eslint-disable-next-line object-curly-newline
import EmailBioPanel from './email-bio-panel/EmailBioPanel.vue'

// import ProjectTabInternalNotes from './project-tab-internal-notes/ProjectTabInternalNotes.vue'
import EmailTabEditor from './email-tab-editor/EmailTabEditor.vue'
import BreadCrumbs from '@/components/files-table/BreadCrumbs.vue'

export default {
  components: {
    BreadCrumbs,
    EmailBioPanel,
    EmailTabEditor,
  },
  setup() {
    const { getEmailTemplate } = useActions('jobs', ['getEmailTemplate'])
    const { emailTemplateData } = useState('jobs', ['emailTemplateData'])
    const { SET_EMAIL_TEMPLATE_DATA } = useMutations('jobs', ['SET_EMAIL_TEMPLATE_DATA'])

    const fetchEmailTemplateData = async () => {
      await getEmailTemplate(router.currentRoute.params.id)
    }

    onMounted(async () => {
      await fetchEmailTemplateData()
    })

    onUnmounted(() => {
      SET_EMAIL_TEMPLATE_DATA({})
    })

    return {
      fetchEmailTemplateData,

      emailTemplateData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
