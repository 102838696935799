<template>
  <div class="user-tab-security">
    <v-card class="mb-7">
      <v-card-title class="d-flex align-center justify-space-between">
        <span v-t="'editor'" />
        <span>
          <v-btn v-t="'discard'" outlined color="secondary" small @click="restoreEmailTemplateData" />
          <v-dialog v-model="isSendTestEmailDialogOpen" max-width="650px" scrollable>
            <template #activator="{ on, attrs }">
              <v-btn
                class="mx-3"
                color="primary"
                outlined
                small
                v-bind="attrs"
                v-on="on"
                @click="isSendTestEmailDialogOpen = true"
                >{{ $t('testEmail') }}</v-btn
              >
            </template>
            <v-card class="pa-sm-10 pa-3">
              <v-card-title v-t="'send'" class="justify-center text-h5" />
              <v-card-text style="max-height: 500px">
                <v-form
                  ref="testEmailVariablesForm"
                  v-model="testEmailVariablesValid"
                  class="multi-col-validation"
                  @submit.prevent="sendTestEmailHandler"
                >
                  <v-subheader>Email</v-subheader>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="emailToTest"
                        outlined
                        dense
                        maxlength="150"
                        hide-details="auto"
                        :label="$t('emailTo')"
                        :placeholder="$t('emailTo')"
                        :rules="[validators.required, validators.multipleEmailValidator]"
                        :hint="$t('separateByComma')"
                      />
                    </v-col>
                  </v-row>
                  <v-subheader v-t="'variables'" />
                  <v-row v-for="(variableData, index) in testEmailVariables" :key="index">
                    <v-col v-if="isEmailVariableUsed(variableData.variable)" cols="12">
                      <v-text-field
                        v-model="variableData.testValue"
                        :label="`${variableData.label}*`"
                        :maxlength="variableData.maxLength"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="[validators.required]"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="d-flex justify-center mt-3">
                      <v-btn
                        v-t="'discard'"
                        outlined
                        color="secondary"
                        class="me-3"
                        @click="closeSendTestEmailDialog"
                      />
                      <v-btn
                        color="primary"
                        type="submit"
                        :loading="sendingTestEmail"
                        :disabled="!testEmailVariablesValid || !localSubject.length"
                      >
                        <template v-if="!showCheckIcon">
                          <span v-t="'send'" class="me-2" />
                          <v-icon small>{{ icons.mdiSendOutline }}</v-icon>
                        </template>
                        <template v-else>
                          <v-icon>{{ icons.mdiCheck }}</v-icon>
                        </template>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-btn
            color="primary"
            small
            :disabled="!areDataChanged || !emailTemplateDataValid || !localBodyHtml.length"
            :loading="emailTemplateDataLoading"
            @click="handleEmailTemplateSubmit"
            >{{ $t('save') }}</v-btn
          >
        </span>
      </v-card-title>
      <v-card-text>
        <v-form v-model="emailTemplateDataValid" @submit.prevent="handleEmailTemplateSubmit">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="localSubject"
                outlined
                dense
                maxlength="150"
                hide-details="auto"
                :label="$t('subject')"
                :placeholder="$t('subject')"
                :rules="[validators.required]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <summer-note
                :key="rerenderSummernoteTrigger"
                :email-variables="emailVariablesSummernote"
                hide-link-and-image-button
                @ready="readySummerNote"
              />
              <div class="float-right text-xs">{{ localBodyHtml.length }} / 20000</div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ref, computed, getCurrentInstance, watch } from '@vue/composition-api'
import { useActions, useState } from 'vuex-composition-helpers'
import { mdiCheck, mdiSendOutline } from '@mdi/js'

import { required, multipleEmailValidator } from '@core/utils/validation'

import SummerNote from '@/plugins/summer-note/SummerNote.vue'

import {
  TEST_EMAIL_VARIABLES_WHEN_STATUS_CHANGED_DATA,
  TEST_EMAIL_VARIABLES_RENEW_TALENT_POOL_CONSENT_DATA,
  TEST_EMAIL_VARIABLES_INVITE_TO_JOB_FROM_TALENT_POOL_DATA,
  EMAIL_VARIABLES_WHEN_STATUS_CHANGED_SUMMERNOTE,
  EMAIL_VARIABLES_RENEW_TALENT_POOL_CONSENT_SUMMERNOTE,
  EMAIL_VARIABLES_INVITE_TO_JOB_FROM_TALENT_POOL_SUMMERNOTE,
} from '@/plugins/summer-note/variables'

const EMAIL_RENEW_TALENT_POOL_CONSENT_ACTION = 'renew_tp_consent'
const EMAIL_INVITE_TO_JOB_FROM_TALENT_POOL_ACTION = 'invite_to_job_tp'

export default {
  components: {
    SummerNote,
  },
  setup() {
    const vm = getCurrentInstance().proxy

    const { updateEmailTemplate, sendTestEmail } = useActions('jobs', ['updateEmailTemplate', 'sendTestEmail'])
    const { emailTemplateData } = useState('jobs', ['emailTemplateData'])

    const emailTemplateDataLoading = ref(false)
    const emailTemplateDataValid = ref(false)

    const localSubject = ref('')
    const localBodyHtml = ref('')

    localSubject.value = emailTemplateData.value.subject
    localBodyHtml.value = emailTemplateData.value.bodyHtml

    const editor = ref(null)
    const rerenderSummernoteTrigger = ref(false)
    const testEmailVariables = ref([])
    testEmailVariables.value = TEST_EMAIL_VARIABLES_WHEN_STATUS_CHANGED_DATA(vm)
    const emailVariablesSummernote = ref([])
    emailVariablesSummernote.value = EMAIL_VARIABLES_WHEN_STATUS_CHANGED_SUMMERNOTE(vm)

    const readySummerNote = (initializedEditor) => {
      editor.value = initializedEditor
      editor.value.summernote('code', localBodyHtml.value)
      editor.value.$on('change', (content) => {
        localBodyHtml.value = content
      })
    }

    const areDataChanged = computed(() => {
      return (
        emailTemplateData.value.bodyHtml !== localBodyHtml.value ||
        emailTemplateData.value.subject !== localSubject.value
      )
    })

    const handleEmailTemplateSubmit = async () => {
      if (!areDataChanged) return

      if (!emailTemplateDataValid.value) return

      emailTemplateDataLoading.value = true

      await updateEmailTemplate({
        id: emailTemplateData.value.id,
        payload: {
          subject: localSubject.value,
          bodyHtml: localBodyHtml.value,
        },
      })

      localBodyHtml.value = emailTemplateData.value.bodyHtml
      localSubject.value = emailTemplateData.value.subject

      emailTemplateDataLoading.value = false
    }

    const restoreEmailTemplateData = () => {
      localBodyHtml.value = emailTemplateData.value.bodyHtml
      localSubject.value = emailTemplateData.value.subject
    }

    watch(
      () => emailTemplateData.value.action,
      (newValue, oldValue) => {
        if (
          newValue === EMAIL_RENEW_TALENT_POOL_CONSENT_ACTION &&
          oldValue !== EMAIL_RENEW_TALENT_POOL_CONSENT_ACTION
        ) {
          testEmailVariables.value = TEST_EMAIL_VARIABLES_RENEW_TALENT_POOL_CONSENT_DATA(vm)
          emailVariablesSummernote.value = EMAIL_VARIABLES_RENEW_TALENT_POOL_CONSENT_SUMMERNOTE(vm)
          editor.value.summernote('destroy')
          rerenderSummernoteTrigger.value = !rerenderSummernoteTrigger.value
        } else if (
          newValue === EMAIL_INVITE_TO_JOB_FROM_TALENT_POOL_ACTION &&
          oldValue !== EMAIL_INVITE_TO_JOB_FROM_TALENT_POOL_ACTION
        ) {
          testEmailVariables.value = TEST_EMAIL_VARIABLES_INVITE_TO_JOB_FROM_TALENT_POOL_DATA(vm)
          emailVariablesSummernote.value = EMAIL_VARIABLES_INVITE_TO_JOB_FROM_TALENT_POOL_SUMMERNOTE(vm)
          editor.value.summernote('destroy')
          rerenderSummernoteTrigger.value = !rerenderSummernoteTrigger.value
        } else {
          testEmailVariables.value = TEST_EMAIL_VARIABLES_WHEN_STATUS_CHANGED_DATA(vm)
          emailVariablesSummernote.value = EMAIL_VARIABLES_WHEN_STATUS_CHANGED_SUMMERNOTE(vm)
          editor.value.summernote('destroy')
          rerenderSummernoteTrigger.value = !rerenderSummernoteTrigger.value
        }
      },
    )

    const isSendTestEmailDialogOpen = ref(false)
    const emailToTest = ref('')
    const sendingTestEmail = ref(false)
    const showCheckIcon = ref(false)
    const testEmailVariablesValid = ref(false)
    const testEmailVariablesForm = ref(null)

    const isEmailVariableUsed = (v) => {
      const variable = `{{ ${v} }}`

      return localBodyHtml.value.includes(variable) || localSubject.value.includes(variable)
    }

    const closeSendTestEmailDialog = () => {
      testEmailVariablesForm.value.reset()
      isSendTestEmailDialogOpen.value = false
    }

    const sendTestEmailHandler = async () => {
      sendingTestEmail.value = true
      const emailSendSuccessfully = await sendTestEmail({
        emailsTo: emailToTest.value,
        subject: localSubject.value,
        lang: emailTemplateData.value.lang,
        bodyHtml: localBodyHtml.value,
        variables: testEmailVariables.value,
        action: emailTemplateData.value.action,
      })
      sendingTestEmail.value = false
      if (emailSendSuccessfully) {
        showCheckIcon.value = true
        setTimeout(() => {
          showCheckIcon.value = false
          closeSendTestEmailDialog()
        }, 2500)
      }
    }

    return {
      readySummerNote,
      handleEmailTemplateSubmit,
      restoreEmailTemplateData,
      isEmailVariableUsed,
      sendTestEmailHandler,
      closeSendTestEmailDialog,

      areDataChanged,

      isSendTestEmailDialogOpen,
      emailToTest,
      sendingTestEmail,
      testEmailVariablesValid,
      testEmailVariablesForm,
      showCheckIcon,

      localSubject,
      localBodyHtml,
      emailTemplateDataLoading,
      emailTemplateDataValid,
      testEmailVariables,
      emailVariablesSummernote,
      rerenderSummernoteTrigger,

      validators: {
        required,
        multipleEmailValidator,
      },

      icons: {
        mdiCheck,
        mdiSendOutline,
      },
    }
  },
}
</script>
